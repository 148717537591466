import React from "react";
import { retry } from "./utils/RetryLoadable";
import ValidDate from "./pages/finance/valid_date";

const Reservation = React.lazy(() =>
  retry(() => import("./pages/reservation/Reservation")),
);
const CreateReservation = React.lazy(() =>
  retry(() => import("./pages/reservation/CreateReservation")),
);
const EditReservation = React.lazy(() =>
  retry(() => import("./pages/reservation/EditReservation")),
);

const Transaction = React.lazy(() =>
  retry(() => import("./pages/transaction/Transaction")),
);
const EditTransaction = React.lazy(() =>
  retry(() => import("./pages/transaction/EditTransaction")),
);

const Product = React.lazy(() =>
  retry(() => import("./pages/products/Products")),
);
const EditProduct = React.lazy(() =>
  retry(() => import("./pages/products/EditProduct")),
);

const Task = React.lazy(() => retry(() => import("./pages/task/Task")));

const Incentive = React.lazy(() =>
  retry(() => import("./pages/commercial/incentives/Incentive")),
);

const Arranger = React.lazy(() =>
  retry(() => import("./pages/arranger/Arranger")),
);
const CreateArranger = React.lazy(() =>
  retry(() => import("./pages/arranger/form/CreateArranger")),
);
const EditArranger = React.lazy(() =>
  retry(() => import("./pages/arranger/form/EditArranger")),
);

// const Passenger = React.lazy(() =>
//   retry(() => import("./pages/passenger/Passenger")),
// );
// const EditPassenger = React.lazy(() =>
//   retry(() => import("./pages/passenger/form/EditPassenger")),
// );

const Company = React.lazy(() =>
  retry(() => import("./pages/company/Company")),
);
const CreateCompany = React.lazy(() =>
  retry(() => import("./pages/company/form/CreateCompany")),
);
const EditCompany = React.lazy(() =>
  retry(() => import("./pages/company/form/EditCompany")),
);

const Account = React.lazy(() =>
  retry(() => import("./pages/account/Account")),
);
const EditCompanyAccount = React.lazy(() =>
  retry(() => import("./pages/account/EditCompanyAccount")),
);

const Campaigns = React.lazy(() =>
  retry(() => import("./pages/commercial/campaign")),
);
const CreateCampaign = React.lazy(() =>
  retry(() => import("./pages/commercial/campaign/CreateCampaign")),
);
const EditCampaign = React.lazy(() =>
  retry(() => import("./pages/commercial/campaign/EditCampaign")),
);

const PushNotification = React.lazy(() =>
  retry(() => import("./pages/commercial/push_notification")),
);
const CreatePushNotification = React.lazy(() =>
  retry(() =>
    import("./pages/commercial/push_notification/CreatePushNotification"),
  ),
);
const EditPushNotification = React.lazy(() =>
  retry(() =>
    import("./pages/commercial/push_notification/EditPushNotification"),
  ),
);

const Promotions = React.lazy(() =>
  retry(() => import("./pages/commercial/promotion")),
);
const CreatePromotion = React.lazy(() =>
  retry(() => import("./pages/commercial/promotion/CreatePromotion")),
);
const EditPromotion = React.lazy(() =>
  retry(() => import("./pages/commercial/promotion/EditPromotion")),
);
const PromoCode = React.lazy(() =>
  retry(() => import("./pages/commercial/incentives/promo_code")),
);
const CreatePromoCode = React.lazy(() =>
  retry(() =>
    import("./pages/commercial/incentives/promo_code/CreatePromoCode"),
  ),
);
const EditPromoCode = React.lazy(() =>
  retry(() => import("./pages/commercial/incentives/promo_code/EditPromoCode")),
);

//tour package routes
const TourPackages = React.lazy(() =>
  retry(() => import("./pages/commercial/tour_packages")),
);
const CreateTourPackage = React.lazy(() =>
  retry(() => import("./pages/commercial/tour_packages/CreateTourPackage")),
);
const EditTourPackage = React.lazy(() =>
  retry(() => import("./pages/commercial/tour_packages/EditTourPackage")),
);

const CashBack = React.lazy(() =>
  retry(() => import("./pages/commercial/incentives/cashback")),
);

const ProductType = React.lazy(() =>
  retry(() => import("./pages/setup/ProductType")),
);
const CreateProductType = React.lazy(() =>
  retry(() => import("./pages/setup/product_type/CreateProductType")),
);
const EditProductType = React.lazy(() =>
  retry(() => import("./pages/setup/product_type/EditProductType")),
);

const ShortLinkQR = React.lazy(() =>
  retry(() => import("./pages/setup/shortlink_qr")),
);
const CreateShortLinkQR = React.lazy(() =>
  retry(() => import("./pages/setup/shortlink_qr/CreateShortLinkQR")),
);
const EditShortLinkQR = React.lazy(() =>
  retry(() => import("./pages/setup/shortlink_qr/EditShortLinkQR")),
);

const PaymentMethod = React.lazy(() =>
  retry(() => import("./pages/setup/PaymentMethod")),
);
const CreatePaymentMethod = React.lazy(() =>
  retry(() => import("./pages/setup/payment_method/CreatePaymentMethod")),
);
const EditPaymentMethod = React.lazy(() =>
  retry(() => import("./pages/setup/payment_method/EditPaymentMethod")),
);

const Logins = React.lazy(() => retry(() => import("./pages/setup/Logins")));
const CreateLogin = React.lazy(() =>
  retry(() => import("./pages/setup/logins/CreateLogin")),
);
const EditLogin = React.lazy(() =>
  retry(() => import("./pages/setup/logins/EditLogin")),
);

const Vendors = React.lazy(() => retry(() => import("./pages/setup/Vendors")));
const CreateVendor = React.lazy(() =>
  retry(() => import("./pages/setup/vendor/CreateVendor")),
);
const EditVendor = React.lazy(() =>
  retry(() => import("./pages/setup/vendor/EditVendor")),
);

const Operators = React.lazy(() =>
  retry(() => import("./pages/setup/Operators")),
);
const CreateOperator = React.lazy(() =>
  retry(() => import("./pages/setup/operator/CreateOperator")),
);
const EditOperator = React.lazy(() =>
  retry(() => import("./pages/setup/operator/EditOperator")),
);

const Roles = React.lazy(() => retry(() => import("./pages/setup/Roles")));
const CreateRole = React.lazy(() =>
  retry(() => import("./pages/setup/role/CreateRole")),
);
const EditRole = React.lazy(() =>
  retry(() => import("./pages/setup/role/EditRole")),
);
const Clients = React.lazy(() => retry(() => import("./pages/setup/clients")));
const BusClassType = React.lazy(() =>
  retry(() => import("./pages/setup/bus_class_type")),
);
const CreateBusClassType = React.lazy(() =>
  retry(() => import("./pages/setup/bus_class_type/CreateBusClassType")),
);
const EditBusClassType = React.lazy(() =>
  retry(() => import("./pages/setup/bus_class_type/EditBusClassType")),
);
const CreateClient = React.lazy(() =>
  retry(() => import("./pages/setup/clients/CreateClient")),
);
const EditClient = React.lazy(() =>
  retry(() => import("./pages/setup/clients/EditClient")),
);

const LoyaltyTier = React.lazy(() =>
  retry(() => import("./pages/setup/loyalty_tier")),
);
const CreateLoyaltyTier = React.lazy(() =>
  retry(() => import("./pages/setup/loyalty_tier/CreateLoyaltyTier")),
);
const EditLoyaltyTier = React.lazy(() =>
  retry(() => import("./pages/setup/loyalty_tier/EditLoyaltyTier")),
);

const Airport = React.lazy(() => retry(() => import("./pages/setup/airport")));
const CreateAirport = React.lazy(() =>
  retry(() => import("./pages/setup/airport/CreateAirport")),
);
const EditAirport = React.lazy(() =>
  retry(() => import("./pages/setup/airport/EditAirport")),
);

const ClassBreakdown = React.lazy(() =>
  retry(() => import("./pages/setup/class_breakdown")),
);
const CreateClassBreakdown = React.lazy(() =>
  retry(() => import("./pages/setup/class_breakdown/CreateClassBreakdown")),
);
const EditClassBreakdown = React.lazy(() =>
  retry(() => import("./pages/setup/class_breakdown/EditClassBreakdown")),
);

const ClassBreakdownSector = React.lazy(() =>
  retry(() => import("./pages/setup/class_breakdown_sector")),
);
const CreateClassBreakdownSector = React.lazy(() =>
  retry(() =>
    import("./pages/setup/class_breakdown_sector/CreateClassBreakdownSector"),
  ),
);
const EditClassBreakdownSector = React.lazy(() =>
  retry(() =>
    import("./pages/setup/class_breakdown_sector/EditClassBreakdownSector"),
  ),
);

const FlightMarCalc = React.lazy(() =>
  retry(() => import("./pages/setup/flight_mar_calc")),
);
const CreateFlightMarCalc = React.lazy(() =>
  retry(() => import("./pages/setup/flight_mar_calc/CreateFlightMarCalc")),
);
const EditFlightMarCalc = React.lazy(() =>
  retry(() => import("./pages/setup/flight_mar_calc/EditFlightMarCalc")),
);

const FlightMarCalcSector = React.lazy(() =>
  retry(() => import("./pages/setup/flight_mar_calc_sector")),
);
const CreateFlightMarCalcSector = React.lazy(() =>
  retry(() =>
    import("./pages/setup/flight_mar_calc_sector/CreateFlightMarCalcSector"),
  ),
);
const EditFlightMarCalcSector = React.lazy(() =>
  retry(() =>
    import("./pages/setup/flight_mar_calc_sector/EditFlightMarCalcSector"),
  ),
);

const Bank = React.lazy(() => retry(() => import("./pages/finance/bank/Bank")));
const CreateBank = React.lazy(() =>
  retry(() => import("./pages/finance/bank/form/CreateBank")),
);
const EditBank = React.lazy(() =>
  retry(() => import("./pages/finance/bank/form/EditBank")),
);

const BankAccount = React.lazy(() =>
  retry(() => import("./pages/finance/bank_account/BankAccount")),
);
const EditBankAccount = React.lazy(() =>
  retry(() => import("./pages/finance/bank_account/EditBankAccount")),
);
const ChartOfAccount = React.lazy(() =>
  retry(() => import("./pages/finance/chart_of_accounts/COAS")),
);
const EditCOA = React.lazy(() =>
  retry(() => import("./pages/finance/chart_of_accounts/EditCOA")),
);
const ImportLogView = React.lazy(() =>
  retry(() => import("./pages/finance/bank_transaction/ImportLogView")),
);
const BankTransaction = React.lazy(() =>
  retry(() => import("./pages/finance/bank_transaction/BankTransaction")),
);
const CreateBankTransaction = React.lazy(() =>
  retry(() =>
    import("./pages/finance/bank_transaction/form/CreateBankTransaction"),
  ),
);
const EditBankTransaction = React.lazy(() =>
  retry(() =>
    import("./pages/finance/bank_transaction/form/EditBankTransaction"),
  ),
);

const FinancialTransaction = React.lazy(() =>
  retry(() =>
    import("./pages/finance/financial_transaction/FinancialTransaction"),
  ),
);
const CreateFinancialTransaction = React.lazy(() =>
  retry(() =>
    import(
      "./pages/finance/financial_transaction/form/CreateFinancialTransaction"
    ),
  ),
);
const EditFinancialTransaction = React.lazy(() =>
  retry(() =>
    import(
      "./pages/finance/financial_transaction/form/EditFinancialTransaction"
    ),
  ),
);
const FinancialMatchingTool = React.lazy(() =>
  retry(() => import("./pages/finance/matching_tool/MatchingTool")),
);
const Currency = React.lazy(() =>
  retry(() => import("./pages/finance/Currency")),
);

const TableSyncLog = React.lazy(() =>
  retry(() => import("./components/table/TableSyncLog")),
);

const PermissionsPage = React.lazy(() =>
  retry(() => import("./pages/setup/Permissions")),
);

const MobileAppVersionPage = React.lazy(() =>
  retry(() => import("./pages/setup/mobileapp_version")),
);
const CreateMobileAppVersionPage = React.lazy(() =>
  retry(() => import("./pages/setup/mobileapp_version/CreateVersion")),
);
const EditMobileAppVersionPage = React.lazy(() =>
  retry(() => import("./pages/setup/mobileapp_version/EditVersion")),
);

const ReferralsPage = React.lazy(() =>
  retry(() => import("./pages/commercial/incentives/referrals")),
);
const ArrangerReferralsPage = React.lazy(() =>
  retry(() => import("./pages/commercial/incentives/arranger_referrals")),
);
const CompanyReferralsPage = React.lazy(() =>
  retry(() => import("./pages/commercial/incentives/company_referrals")),
);
const ReferralReservationPage = React.lazy(() =>
  retry(() => import("./pages/commercial/incentives/referral_reservation")),
);

const PeopleReferralFormPage = React.lazy(() =>
  retry(() =>
    import("./pages/commercial/incentives/referrals/PeopleReferralForm"),
  ),
);

const CompanyReferralFormPage = React.lazy(() =>
  retry(() =>
    import("./pages/commercial/incentives/referrals/CompanyReferralForm"),
  ),
);

const CreateCashBack = React.lazy(() =>
  retry(() => import("./pages/commercial/incentives/cashback/CreateCashBack")),
);

const EditCashBack = React.lazy(() =>
  retry(() => import("./pages/commercial/incentives/cashback/EditCashBack")),
);

const OfflineBusProducts = React.lazy(() =>
  retry(() => import("./pages/setup/offline_bus_products")),
);

const OfflineFlightProducts = React.lazy(() =>
  retry(() => import("./pages/setup/offline_flight_products")),
);

const EditOfflineBus = React.lazy(() =>
  retry(() => import("./pages/setup/offline_bus_products/EditOfflineBus")),
);
const CreateOfflineBus = React.lazy(() =>
  retry(() => import("./pages/setup/offline_bus_products/CreateOfflineBus")),
);
const EditOfflineFlight = React.lazy(() =>
  retry(() =>
    import("./pages/setup/offline_flight_products/EditOfflineFlight"),
  ),
);
const CreateOfflineFlight = React.lazy(() =>
  retry(() =>
    import("./pages/setup/offline_flight_products/CreateOfflineFlight"),
  ),
);
const FlightValidation = React.lazy(() =>
  retry(() => import("./pages/setup/offline_flight_products/FlightValidation")),
);
const BusValidation = React.lazy(() =>
  retry(() => import("./pages/setup/offline_bus_products/BusValidation")),
);

const FarePackageImport = React.lazy(() =>
  retry(() => import("./pages/setup/farepackageimport")),
);

const EditFarePackage = React.lazy(() =>
  retry(() => import("./pages/setup/farepackageimport/EditFarePackage")),
);

const ExportProgress = React.lazy(() =>
  retry(() => import("./pages/finance/export_progress")),
);

//hotel-manager-page
const HotelManager = React.lazy(() =>
  retry(() => import("./pages/hotel-manager/HotelManager")),
);

const CreateHotelRoom = React.lazy(() =>
  retry(() => import("./pages/hotel-manager/form/CreateHotelRoom")),
);
const EditHotelRoom = React.lazy(() =>
  retry(() => import("./pages/hotel-manager/form/EditHotelRoom")),
);
const routes = [
  // { path: "/", exact: true, name: "Home" },
  {
    path: "/reservations",
    exact: true,
    name: "Reservations",
    element: Reservation,
  },
  {
    path: "/reservations/create",
    exact: true,
    name: "Create Reservation",
    element: CreateReservation,
  },
  {
    path: "/reservations/edit/:id",
    exact: true,
    name: `${window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1,
    )}`,
    element: EditReservation,
  },
  {
    path: "/operation-transactions",
    exact: true,
    name: "Operation Transactions",
    element: Transaction,
  },
  {
    path: "/operation-transactions/edit/:id",
    exact: true,
    name: "Edit Operation Transaction",
    element: EditTransaction,
  },
  {
    path: "/products",
    exact: true,
    name: "Products",
    element: Product,
  },
  {
    path: "/products/edit/:id",
    exact: true,
    name: "Edit Product",
    element: EditProduct,
  },
  {
    path: "/tasks",
    exact: true,
    name: "Tasks",
    element: Task,
  },
  {
    path: "/incentives",
    exact: true,
    name: "Incentives",
    element: Incentive,
  },
  { path: "/arrangers", exact: true, name: "Arranger", element: Arranger },
  {
    path: "/arrangers/create",
    exact: true,
    name: "Create Arranger",
    element: CreateArranger,
  },
  {
    path: "/arrangers/edit/:id",
    exact: true,
    name: "Edit Arranger",
    element: EditArranger,
  },
  //{ path: "/passengers", exact: true, name: "Passenger", element: Passenger },
  // {
  //   path: "/passengers/edit/:id",
  //   exact: true,
  //   name: "Edit Passenger",
  //   element: EditPassenger,
  // },
  { path: "/company", exact: true, name: "Company", element: Company },
  {
    path: "/company/create",
    exact: true,
    name: "Create Company",
    element: CreateCompany,
  },
  {
    path: "/company/edit/:id",
    exact: true,
    name: "Edit Company",
    element: EditCompany,
  },
  {
    path: "/company-accounts",
    exact: true,
    name: "Company Accounts",
    element: Account,
  },
  {
    path: "/company-accounts/edit/:id",
    exact: true,
    name: "Edit Company Account",
    element: EditCompanyAccount,
  },
  {
    path: "/commercial/campaigns",
    exact: true,
    name: "Commercial / Campaigns",
    element: Campaigns,
  },
  {
    path: "/commercial/campaigns/create",
    exact: true,
    name: "Create Campaign",
    element: CreateCampaign,
  },
  {
    path: "/commercial/campaigns/edit/:id",
    exact: true,
    name: "Edit Campaign",
    element: EditCampaign,
  },
  {
    path: "/commercial/push-notification",
    exact: true,
    name: "Commercial / Push Notification",
    element: PushNotification,
  },
  {
    path: "/commercial/push-notification/create",
    exact: true,
    name: "Create Push Notification",
    element: CreatePushNotification,
  },
  {
    path: "/commercial/push-notification/edit/:id",
    exact: true,
    name: "Edit Push Notification",
    element: EditPushNotification,
  },
  {
    path: "/incentives/promo-code",
    exact: true,
    name: "incentives / Promo Codes",
    element: PromoCode,
  },
  {
    path: "/incentives/promo-code/create",
    exact: true,
    name: "Create Promote Code",
    element: CreatePromoCode,
  },
  {
    path: "/incentives/promo-code/edit/:id",
    exact: true,
    name: "Edit Promote Code",
    element: EditPromoCode,
  },
  {
    path: "/incentives/cashback",
    exact: true,
    name: "Incentives / CashBacks",
    element: CashBack,
  },
  {
    path: "/incentives/cashback/create",
    exact: true,
    name: "Create CashBack",
    element: CreateCashBack,
  },
  {
    path: "/incentives/cashback/edit/:id",
    exact: true,
    name: "Edit CashBack",
    element: EditCashBack,
  },
  {
    path: "/commercial/promotions",
    exact: true,
    name: "Commercial / Promotions",
    element: Promotions,
  },
  {
    path: "/commercial/promotions/create",
    exact: true,
    name: "Create Promotion",
    element: CreatePromotion,
  },
  {
    path: "/commercial/promotions/edit/:id",
    exact: true,
    name: "Edit Promotion",
    element: EditPromotion,
  },

  //tour package
  {
    path: "/commercial/tour-packages",
    exact: true,
    name: "Commercial / Tour Packages",
    element: TourPackages,
  },
  {
    path: "/commercial/tour-packages/create",
    exact: true,
    name: "Create Tour Package",
    element: CreateTourPackage,
  },
  {
    path: "/commercial/tour-packages/edit/:id",
    exact: true,
    name: "Edit Tour Package",
    element: EditTourPackage,
  },
  {
    path: "/setup/product-type",
    exact: true,
    name: "Setup / Product Type",
    element: ProductType,
  },
  {
    path: "/setup/product-type/create",
    exact: true,
    name: "Create Product Type",
    element: CreateProductType,
  },
  {
    path: "/setup/product-type/edit/:id",
    exact: true,
    name: "Edit Product Type",
    element: EditProductType,
  },
  {
    path: "/setup/bus-class-type",
    exact: true,
    name: "Setup / Bus Class Type",
    element: BusClassType,
  },
  {
    path: "/setup/bus-class-type/create",
    exact: true,
    name: "Create Bus Class Type",
    element: CreateBusClassType,
  },
  {
    path: "/setup/bus-class-type/edit/:id",
    exact: true,
    name: "Edit Bus Class Type",
    element: EditBusClassType,
  },
  {
    path: "/setup/shortlink-qr",
    exact: true,
    name: "Setup / Short Link QR",
    element: ShortLinkQR,
  },
  {
    path: "/setup/shortlink-qr/create",
    exact: true,
    name: "Create Short Link QR",
    element: CreateShortLinkQR,
  },
  {
    path: "/setup/shortlink-qr/edit/:id",
    exact: true,
    name: "Edit Short Link QR",
    element: EditShortLinkQR,
  },
  {
    path: "/setup/payment-method",
    exact: true,
    name: "Setup / Payment Method",
    element: PaymentMethod,
  },
  {
    path: "/setup/payment-method/create",
    exact: true,
    name: "Create Payment Method",
    element: CreatePaymentMethod,
  },
  {
    path: "/setup/payment-method/edit/:id",
    exact: true,
    name: "Edit Payment Method",
    element: EditPaymentMethod,
  },
  {
    path: "/setup/logins",
    exact: true,
    name: "Setup / Login",
    element: Logins,
  },
  {
    path: "/setup/logins/create",
    exact: true,
    name: "Create Login",
    element: CreateLogin,
  },
  {
    path: "/setup/logins/edit/:id",
    exact: true,
    name: "Edit Login",
    element: EditLogin,
  },
  {
    path: "/setup/vendors",
    exact: true,
    name: "Setup / Vendors",
    element: Vendors,
  },
  {
    path: "/setup/vendors/create",
    exact: true,
    name: "Create Vendor",
    element: CreateVendor,
  },
  {
    path: "/setup/vendors/edit/:id",
    exact: true,
    name: "Edit Vendor",
    element: EditVendor,
  },
  {
    path: "/setup/operators",
    exact: true,
    name: "Setup / Operators",
    element: Operators,
  },
  {
    path: "/setup/operators/create",
    exact: true,
    name: "Create Operator",
    element: CreateOperator,
  },
  {
    path: "/setup/operators/edit/:id",
    exact: true,
    name: "Edit Operator",
    element: EditOperator,
  },
  {
    path: "/setup/roles",
    exact: true,
    name: "Setup / Roles",
    element: Roles,
  },
  {
    path: "/setup/roles/create",
    exact: true,
    name: "Create Role",
    element: CreateRole,
  },
  {
    path: "/setup/roles/edit/:id",
    exact: true,
    name: "Edit Role",
    element: EditRole,
  },
  {
    path: "/setup/hotel-manager",
    exact: true,
    name: "Setup / Hotel Manager",
    element: HotelManager,
  },
  {
    path: "/setup/hotel-manager/create",
    exact: true,
    name: "Create Hotel ",
    element: CreateHotelRoom,
  },
  {
    path: "/setup/hotel-manager/edit/:id",
    exact: true,
    name: "Edit Hotel ",
    element: EditHotelRoom,
  },
  {
    path: "/setup/loyalty-tier",
    exact: true,
    name: "Setup / Loyalty Tier",
    element: LoyaltyTier,
  },
  {
    path: "/setup/loyalty-tier/create",
    exact: true,
    name: "Create Loyalty Tier",
    element: CreateLoyaltyTier,
  },
  {
    path: "/setup/loyalty-tier/edit/:id",
    exact: true,
    name: "Edit Loyalty Tier",
    element: EditLoyaltyTier,
  },
  {
    path: "/setup/airport",
    exact: true,
    name: "Setup / Airports",
    element: Airport,
  },
  {
    path: "/setup/airport/create",
    exact: true,
    name: "Create Airport",
    element: CreateAirport,
  },
  {
    path: "/setup/airport/edit/:id",
    exact: true,
    name: "Edit Airport",
    element: EditAirport,
  },
  {
    path: "/setup/client",
    exact: true,
    name: "Setup / Client",
    element: Clients,
  },
  {
    path: "/setup/client/create",
    exact: true,
    name: "Create Client",
    element: CreateClient,
  },
  {
    path: "/setup/client/edit/:id",
    exact: true,
    name: "Edit Client",
    element: EditClient,
  },
  {
    path: "/setup/class-breakdown",
    exact: true,
    name: "Setup / Class Breakdown",
    element: ClassBreakdown,
  },
  {
    path: "/setup/class-breakdown/create",
    exact: true,
    name: "Create Class Breakdown",
    element: CreateClassBreakdown,
  },
  {
    path: "/setup/class-breakdown/edit/:id",
    exact: true,
    name: "Edit Class Breakdown",
    element: EditClassBreakdown,
  },
  {
    path: "/setup/class-breakdown-sector",
    exact: true,
    name: "Setup / Class Breakdown Sector",
    element: ClassBreakdownSector,
  },
  {
    path: "/setup/class-breakdown-sector/create",
    exact: true,
    name: "Create Class Breakdown Sector",
    element: CreateClassBreakdownSector,
  },
  {
    path: "/setup/class-breakdown-sector/edit/:id",
    exact: true,
    name: "Edit Class Breakdown Sector",
    element: EditClassBreakdownSector,
  },

  //offline products
  {
    path: "/offline-products/bus",
    exact: true,
    name: "Offline Products / Bus",
    element: OfflineBusProducts,
  },
  {
    path: "/offline-products/flight",
    exact: true,
    name: "Offline Products / Flight",
    element: OfflineFlightProducts,
  },
  {
    path: "/offline-products/bus/create",
    exact: true,
    name: "Create Offline Bus",
    element: CreateOfflineBus,
  },
  {
    path: "/offline-products/bus/edit/:id",
    exact: true,
    name: "Edit Offline Bus",
    element: EditOfflineBus,
  },
  {
    path: "/offline-products/flight/create",
    exact: true,
    name: "Create Offline Flight",
    element: CreateOfflineFlight,
  },
  {
    path: "/offline-products/flight/edit/:id",
    exact: true,
    name: "Edit Offline Flight",
    element: EditOfflineFlight,
  },
  {
    path: "/offline-products/flight-validation",
    exact: true,
    name: "Offline Products / Flight Validation",
    element: FlightValidation,
  },
  {
    path: "/offline-products/bus-validation",
    exact: true,
    name: "Offline Products / Bus Validation",
    element: BusValidation,
  },
  //fare package import
  {
    path: "/setup/fare_package_import",
    exact: true,
    name: "Setup / Fare Package Import",
    element: FarePackageImport,
  },
  {
    path: "/setup/fare_package_import/edit/:id",
    exact: true,
    name: "Edit Fare Package",
    element: EditFarePackage,
  },
  {
    path: "/setup/flight-mar-calc",
    exact: true,
    name: "Setup / Flight MAR Calc",
    element: FlightMarCalc,
  },
  {
    path: "/setup/flight-mar-calc/create",
    exact: true,
    name: "Create Flight MAR Calc",
    element: CreateFlightMarCalc,
  },
  {
    path: "/setup/flight-mar-calc/edit/:id",
    exact: true,
    name: "Edit Flight MAR Calc",
    element: EditFlightMarCalc,
  },
  {
    path: "/setup/flight-mar-calc-sector",
    exact: true,
    name: "Setup / Flight MAR Calc Sector",
    element: FlightMarCalcSector,
  },
  {
    path: "/setup/flight-mar-calc-sector/create",
    exact: true,
    name: "Create Flight MAR Calc Sector",
    element: CreateFlightMarCalcSector,
  },
  {
    path: "/setup/flight-mar-calc-sector/edit/:id",
    exact: true,
    name: "Edit Flight MAR Calc Sector",
    element: EditFlightMarCalcSector,
  },
  {
    path: "/setup/permissions",
    exact: true,
    name: "Permissions",
    element: PermissionsPage,
  },
  {
    path: "/setup/mobile-app-versions",
    exact: true,
    name: "Mobile App Version",
    element: MobileAppVersionPage,
  },
  {
    path: "/setup/mobile-app-versions/create",
    exact: true,
    name: "Create Mobile App Version",
    element: CreateMobileAppVersionPage,
  },
  {
    path: "/setup/mobile-app-versions/edit/:id",
    exact: true,
    name: "Edit Mobile App Version",
    element: EditMobileAppVersionPage,
  },
  {
    path: "/incentives/referrals",
    exact: true,
    name: "Referrals",
    element: ReferralsPage,
  },
  {
    path: "/incentives/arranger-referrals",
    exact: true,
    name: "Arranger Referrals",
    element: ArrangerReferralsPage,
  },
  {
    path: "/incentives/company-referrals",
    exact: true,
    name: "Company Referrals",
    element: CompanyReferralsPage,
  },
  {
    path: "/incentives/company-referrals/:type/:id",
    exact: true,
    name: "Company Referral Reservation",
    element: ReferralReservationPage,
  },
  {
    path: "/incentives/arranger-referrals/:type/:id",
    exact: true,
    name: "Arranger Referral Reservation",
    element: ReferralReservationPage,
  },
  {
    path: "/incentives/referrals/peopleReferral/create",
    exact: true,
    name: "Create People Referral",
    element: PeopleReferralFormPage,
  },
  {
    path: "/incentives/referrals/peopleReferral/edit/:id",
    exact: true,
    name: "Edit People Referral",
    element: PeopleReferralFormPage,
  },
  {
    path: "/incentives/referrals/companyReferral/create",
    exact: true,
    name: "Create Company Referral",
    element: CompanyReferralFormPage,
  },

  {
    path: "/incentives/referrals/companyReferral/edit/:id",
    exact: true,
    name: "Edit Company Referral",
    element: CompanyReferralFormPage,
  },
  {
    path: "/finance/banks",
    exact: true,
    name: "Banks",
    element: Bank,
  },
  {
    path: "/finance/banks/create",
    exact: true,
    name: "Create Bank",
    element: CreateBank,
  },
  {
    path: "/finance/banks/edit/:id",
    exact: true,
    name: "Edit Bank",
    element: EditBank,
  },
  {
    path: "/finance/bank-accounts",
    exact: true,
    name: "Bank Accounts",
    element: BankAccount,
  },
  {
    path: "/finance/bank-accounts/edit/:id",
    exact: true,
    name: "Edit Bank Account",
    element: EditBankAccount,
  },
  {
    path: "/finance/chart-of-accounts",
    exact: true,
    name: "Chart Of Accounts",
    element: ChartOfAccount,
  },
  {
    path: "/finance/chart-of-accounts/edit/:id",
    exact: true,
    name: "Edit Chart Of Accounts",
    element: EditCOA,
  },
  {
    path: "/finance/bank-transactions/import-logs-view",
    exact: true,
    name: "Import Logs View",
    element: ImportLogView,
  },
  {
    path: "/finance/bank-transactions",
    exact: true,
    name: "Bank Transactions",
    element: BankTransaction,
  },
  {
    path: "/finance/bank-transactions/create",
    exact: true,
    name: "Create Bank Transaction",
    element: CreateBankTransaction,
  },
  {
    path: "/finance/bank-transactions/edit/:id",
    exact: true,
    name: "Edit Bank Transaction",
    element: EditBankTransaction,
  },
  {
    path: "/finance/valid-date",
    exact: true,
    name: "Valid Date",
    element: ValidDate,
  },
  {
    path: "/finance/financial-transactions",
    exact: true,
    name: "Financial Transactions",
    element: FinancialTransaction,
  },
  {
    path: "/finance/financial-transactions/create",
    exact: true,
    name: "Create Financial Transaction",
    element: CreateFinancialTransaction,
  },
  {
    path: "/finance/financial-transactions/edit/:id",
    exact: true,
    name: "Edit Financial Transaction",
    element: EditFinancialTransaction,
  },
  {
    path: "/finance/financial-matching-tool",
    exact: true,
    name: "Financial Matching Tool",
    element: FinancialMatchingTool,
  },
  {
    path: "/currency",
    exact: true,
    name: "Currency",
    element: Currency,
  },
  {
    path: "/finance/financial-transactions/sync-log",
    exact: true,
    name: "Financial Sync Log",
    element: TableSyncLog,
  },
  {
    path: "/products/sync-log",
    exact: true,
    name: "Product Sync Log",
    element: TableSyncLog,
  },
  {
    path: "/operation-transactions/sync-log",
    exact: true,
    name: "Operation Transaction Sync Log",
    element: TableSyncLog,
  },
  {
    path: "/finance/bank-transactions/sync-log",
    exact: true,
    name: "Bank Transaction Sync Log",
    element: TableSyncLog,
  },
  {
    path: "/finance/export-progress",
    exact: true,
    name: "Export Progress",
    element: ExportProgress,
  },
];

export default routes;
