import {
  FINANCIAL_TRANS_TYPE,
  SET_PRODUCT_CHECK_STATUS,
  SET_RESERVATION_PRODUCTS,
  SET_RESERVATION_TRANSACTIONS,
  SET_TRANSACTION_CHECK_STATUS,
  STATUS_TOGGLE,
  FINANCIAL_MATCHING_TYPE,
  FINANCIAL_TAG_TYPE,
  FINANCIAL_TRANS_MENU_FILTER,
} from "../../actions/types";

const initialState = {
  products: [],
  transactions: [],
  productChecked: [],
  transactionChecked: [],
  financial_trans_type: null,
  financial_trans_menu_filter: null,
  financial_matching_type: null,
  financial_tag_type: "partially-matched",
};

const ReservationProducts = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESERVATION_PRODUCTS:
      state.products = action.payload;
      return { ...state };
    case SET_RESERVATION_TRANSACTIONS:
      state.transactions = action.payload;
      return { ...state };
    case SET_PRODUCT_CHECK_STATUS:
      state.productChecked = action.payload;
      return { ...state };
    case SET_TRANSACTION_CHECK_STATUS:
      state.transactionChecked = action.payload;
      return { ...state };
    case STATUS_TOGGLE:
      state.statustoggle = action.payload;
      return { ...state };
    case FINANCIAL_TRANS_TYPE:
      state.financial_trans_type = action.payload;
      return { ...state };
    case FINANCIAL_TRANS_MENU_FILTER:
      state.financial_trans_menu_filter = action.payload;
      return { ...state };
    case FINANCIAL_MATCHING_TYPE:
      state.financial_matching_type = action.payload;
      return { ...state };
    case FINANCIAL_TAG_TYPE:
      state.financial_tag_type = action.payload;
      return { ...state };
    default:
      return state;
  }
};

export default ReservationProducts;
